import PropTypes from "prop-types";
import Layout from "../src/components/Layout";
import Home from "../src/components/Home";
import {
  getHomeCollectionProducts,
  getProductsFromStoreFront,
} from "../src/services/shopify";
import { attributes as seoParameter } from "../content/seo/home.md";
import CustomScript from "../src/components/CustomScript";

const Index = ({ products, baseUrl }) => {
  return (
    <Layout
      headerProps={{ showToastMessage: true }}
      customMetadata={{
        title: seoParameter.title,
        description: seoParameter.description,
        image: seoParameter.image,
        type: seoParameter.type,
      }}
      customHead={<CustomScript name="Home" />}
    >
      <Home products={products} baseUrl={baseUrl} />
    </Layout>
  );
};

Index.propTypes = {
  products: PropTypes.array,
};

/**
 * Getting home rings server side
 * @return {object} props
 */
export async function getStaticProps() {
  const ids = await getHomeCollectionProducts();
  const productsIds = ids.split(",");
  const storeFrontProductData = await getProductsFromStoreFront(productsIds);

  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "frankdarling.com"
      : "frankdarling.xyz";

  return {
    props: {
      products: storeFrontProductData,
      baseUrl,
    },
    revalidate: 120,
  };
}

export default Index;
