/* eslint-disable react/prop-types */
import React from "react";
import Head from "next/head";

/* eslint-disable-next-line */
export default ({ data }) => (
  <Head>
    <script
      type="application/ld+json"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "FAQPage",
          mainEntity: data.map((el) => {
            return {
              "@type": "Question",
              name: el.question,
              acceptedAnswer: {
                "@type": "Answer",
                text: el.answer,
              },
            };
          }),
        }),
      }}
    />
  </Head>
);
