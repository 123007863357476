import React from "react";
import Head from "next/head";
import { capitalizeFirstLetter } from "../../_helpers/functions";
import compact from "lodash/compact";

/* eslint-disable-next-line */
export default ({ type, url, slug, ratingData }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            [
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: compact([
                  {
                    "@type": "ListItem",
                    position: 1,
                    name: "Home",
                    item: "https://frankdarling.com/",
                  },
                  {
                    "@type": "ListItem",
                    position: 2,
                    name: type,
                    item: `https://frankdarling.com${url}`,
                  },
                  slug && {
                    "@type": "ListItem",
                    position: 3,
                    name: capitalizeFirstLetter(slug),
                    item: `https://frankdarling.com${url}/${slug}/`,
                  },
                ]),
              },
              // ratingData && {
              //   "@context": "https://schema.org/",
              //   "@type": "AggregateRating",
              //   ratingValue: ratingData?.aggregateRating,
              //   bestRating: "5",
              //   worstRating: "1",
              //   ratingCount: ratingData?.ratingCount,
              // },
              ratingData && {
                "@context": "https://schema.org/",
                "@type": "Review",
                review: ratingData?.reviews,
              },
            ].filter(Boolean)
          ),
        }}
      />
    </Head>
  );
};
