import React from "react";
import PropTypes from "prop-types";
import EtiquetteScript from "./etiquetteScript";
import FaqScript from "./faqScript";
import ProductScript from "./productScript";
import HomeScript from "./homeScript";
import ProductLandingScript from "./productLandingScript";

const CustomScript = ({
  name,
  data,
  type,
  customLdJson,
  url,
  slug,
  title,
  ratingData,
}) => {
  return (
    <>
      {name === "Etiquette" ? (
        <EtiquetteScript
          data={data}
          customLdJson={customLdJson}
          slug={slug}
          title={title}
        />
      ) : null}
      {name === "FAQ" ? <FaqScript data={data} /> : null}
      {name === "ProductDetail" ? (
        <ProductScript
          data={data}
          type={type}
          slug={slug}
          url={url}
          title={title}
          ratingData={ratingData}
        />
      ) : null}
      {name === "Home" ? <HomeScript /> : null}
      {name === "ProductLanding" ? (
        <ProductLandingScript
          type={type}
          url={url}
          slug={slug}
          ratingData={ratingData}
        />
      ) : null}
    </>
  );
};

CustomScript.propTypes = {
  name: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  type: PropTypes.string,
  customLdJson: PropTypes.bool,
  url: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  ratingData: PropTypes.object,
};

export default CustomScript;
