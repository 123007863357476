/* eslint-disable react/prop-types */
import React from "react";
import Head from "next/head";

/* eslint-disable-next-line */
export default ({ data, customLdJson, slug, title }) => {
  return (
    <Head>
      <script
        type="application/ld+json"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            customLdJson
              ? data
              : {
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  itemListElement: [
                    {
                      "@type": "ListItem",
                      position: 1,
                      name: "Home",
                      item: "https://frankdarling.com/",
                    },
                    {
                      "@type": "ListItem",
                      position: 2,
                      name: "Etiquettes",
                      item: "https://frankdarling.com/etiquette/",
                    },
                    {
                      "@type": "ListItem",
                      position: 3,
                      name: title,
                      item: `https://frankdarling.com/blog/${slug}/`,
                    },
                  ],
                }
          ),
        }}
      />
    </Head>
  );
};
