import React from "react";
import PropTypes from "prop-types";
import AnimatedLink from "./AnimatedLink";
import Image from "next/image";
import parkerThumbnail from "../../public/assets/images/thumbnail-shot.webp";
import classNames from "classnames";
import useDialog from "../hooks/useDialog";
const HeroBanner = ({
  heading,
  subHeading,
  cta,
  backgroundVideo,
  image,
  link,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(false);
  const handleVideoPlay = () => {
    setIsVideoPlaying(true);
  };
  const { dialogType } = useDialog();
  return (
    <section
      className={classNames(
        "md:py-vw-1/8 xl:py-24 relative flex justify-center overflow-hidden w-full bg-black hero-banner",
        dialogType.header
          ? dialogType.holidayHeader
            ? "mt-16 lg:mt-16"
            : "mt-10 lg:mt-12"
          : ""
      )}
    >
      <div className="lg:pb-12 w-10/12 md:w-1/2 sm:w-10/12 relative z-10 flex justify-center flex-col text-white">
        <hgroup className="w-full">
          <h1 className="text-vw-8 md:text-4/5xl 2xl:text-6xl 3xl:text-8xl leading-none font-serif uppercase">
            {heading.top}
            <br />
            {heading.middle}
          </h1>
          <h2 className="text-vw-8 md:text-4/5xl 2xl:text-6xl 3xl:text-8xl leading-none w-11/12 font-sans uppercase ml-auto">
            {heading.bottom}
          </h2>
        </hgroup>
        <p className="text-center md:text-left 3xl:text-2xl my-10 md:mt-11 lg:mt-14 md:mb-6 text-lg leading-6 3xl:leading-8 font-sans">
          {subHeading}
        </p>
        <span className="flex justify-center md:justify-start">
          <AnimatedLink href={link} cta={cta} color="white" />
        </span>
      </div>
      <div className="sm:w-1/3 w-10/12 relative z-10  md:flex items-start hidden">
        <div className="w-full h-full relative block home-loadable">
          <Image
            // loader={() => image}
            src={image}
            alt="Home Banner Image"
            height={1.4}
            width={1}
            blurDataURL={image}
            placeholder="blur"
            layout="responsive"
          />
        </div>
      </div>
      <div className="absolute inset-0 w-full h-full block z-0">
        {isVideoPlaying ? null : (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            loading="eager"
            src={parkerThumbnail.src}
            alt="Video Poster"
            className="h-full absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 max-w-none"
          />
        )}
        <video
          className="h-full absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 max-w-none"
          playsInline
          autoPlay
          muted
          loop
          onPlay={handleVideoPlay}
        >
          <source src={backgroundVideo} />
        </video>
      </div>
    </section>
  );
};

HeroBanner.defaultProps = {
  heading: {
    top: "Find the",
    middle: "perfect ring",
    bottom: "Make it yours",
  },
  link: "#",
  backgroundVideo:
    "https://cdn.shopify.com/s/files/1/0042/2405/7462/files/Parker_Flipped.mov?1222",
  image: "/assets/images/influencers/small-parker-headshot.webp",
};

HeroBanner.propTypes = {
  /**
   * Heading of the section
   */
  heading: PropTypes.shape({
    top: PropTypes.string,
    middle: PropTypes.string,
    bottom: PropTypes.string,
  }).isRequired,
  /**
   * Sub heading
   */
  subHeading: PropTypes.string.isRequired,
  /**
   * CTA text
   */
  cta: PropTypes.string.isRequired,
  /**
   * CTA link
   */
  link: PropTypes.string,
  /**
   * Background loop video
   */
  backgroundVideo: PropTypes.string,
  /**
   * Side image URL
   */
  image: PropTypes.string,
};

export default HeroBanner;
