import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import HeroBanner from "../../stories/HeroBanner";
const InfluencerSection = dynamic(
  () => {
    return import("../../stories/InfluencerSection");
  },
  { ssr: false }
);
// import InfluencerSection from "../../stories/InfluencerSection";
const HowItWorks = dynamic(
  () => {
    return import("../../stories/HowItWorks");
  },
  { ssr: false }
);
// import HowItWorks from "../../stories/HowItWorks";
const NewsLetter = dynamic(
  () => {
    return import("../../stories/NewsLetter");
  },
  { ssr: false }
);
// import NewsLetter from "../../stories/NewsLetter";
const HomePageProducts = dynamic(
  () => {
    return import("../../stories/HomePageProducts");
  },
  { ssr: false }
);
// import HomePageProducts from "../../stories/HomePageProducts";
import { attributes as heroData } from "../../../content/home/hero.md";
import { attributes as influencerData } from "../../../content/home/influencer.md";
import { attributes as howItWorksData } from "../../../content/home/howItWorks.md";
import { attributes as newsLetter } from "../../../content/home/newsLetter.md";
import { attributes as conciergeData } from "../../../content/home/concierge.md";
import Head from "next/head";

const Concierge = dynamic(
  () => {
    return import("../../stories/Concierge");
  },
  { ssr: false }
);

const Home = ({ products, baseUrl }) => {
  const timer = 250;
  const [showPage, setShowPage] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowPage(true);
    }, [timer]);
  }, [timer]);
  return (
    <div className="w-full">
      <Head>
        <link rel="preload" href={heroData?.image} as="image" />
        <link rel="preload" href={heroData?.backgroundVideo} as="video" />
      </Head>
      <HeroBanner {...heroData} />
      {showPage && (
        <>
          <Concierge {...{ ...conciergeData }} />
          <InfluencerSection {...influencerData} />
          <HowItWorks {...howItWorksData} />
          <HomePageProducts products={products} baseUrl={baseUrl} />
          <NewsLetter {...{ ...newsLetter }} />
        </>
      )}
    </div>
  );
};

Home.propTypes = {
  products: PropTypes.array,
};

export default Home;
