import React from "react";
import Head from "next/head";

/* eslint-disable-next-line */
export default () => (
  <Head>
    <script
      type="application/ld+json"
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "@id": "https://frankdarling.com/about/",
          name: "Frank Darling",
          url: "https://frankdarling.com",
          address: {
            "@type": "PostalAddress",
            addressLocality: "New York, NY",
            addressCountry: "United States",
            postalCode: "10013",
            streetAddress: "50 Hudson Street, Floor 3",
          },
          logo: "https://frankdarling.com/assets/images/header/logo.svg",
          description:
            "Frank Darling provides the most accessible online custom engagment ring shopping experience. Build a unique engagement ring with our collection of Lab grown diamonds, natural diamonds and many more along with the help of our experts, for free.",
          sameAs: [
            "https://www.facebook.com/frankdarlingny/",
            "https://www.instagram.com/frankdarling/",
            "https://twitter.com/frankdarlingny/",
          ],
        }),
      }}
    />
  </Head>
);
