/* eslint-disable react/prop-types */
import React from "react";
import Head from "next/head";
import filter from "lodash/filter";
import compact from "lodash/compact";
import map from "lodash/map";

/* eslint-disable-next-line */
export default ({ data, type, slug, url, title, ratingData }) => {
  const isDiamond = type === "diamonds";
  const getMetalPrice = () => {
    const metals = ["YG", "WG", "RG", "PL"];
    let metalPrice = null;
    for (const colorkey of metals) {
      if (data?.variant_details?.[colorkey]) {
        metalPrice = data?.variant_details[colorkey]?.price;
      }
      if (metalPrice) return metalPrice;
    }
  };
  return (
    <Head>
      <script
        type="application/ld+json"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify([
            {
              "@context": "https://schema.org",
              "@type": "Product",
              mpn: !isDiamond ? data.id : data["Diamond ID"],
              name: !isDiamond
                ? data.title
                : `${data["Carat"]} Carat ${data["Shape"]} ${data["Type"]} Diamond`,
              description: !isDiamond
                ? data.small_description
                : `${data["Carat"]} Carat ${data["Shape"]} ${data["Type"]} Diamond`,
              ...(!isDiamond &&
                ratingData && {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: ratingData?.aggregateRating,
                    bestRating: "5",
                    worstRating: "1",
                    ratingCount: ratingData?.ratingCount,
                  },
                }),
              ...(!isDiamond &&
                ratingData && {
                  review: ratingData?.reviews,
                }),
              sku: !isDiamond
                ? filter(data.variant_details, (el, key) => {
                    if (el?.sku && key !== "undefined") {
                      return true;
                    } else {
                      return false;
                    }
                  })[0]?.sku
                : data["GIA Number"],
              brand: {
                "@type": "Brand",
                name: "Frank Darling",
              },
              offers: !isDiamond
                ? compact(
                    map(data.variant_details, (el, key) => {
                      if (el?.price && key !== "undefined") {
                        return {
                          "@type": "Offer",
                          url: `https://frankdarling.com${url}/${data.handle}/${
                            type === "engagemnt rings"
                              ? `?colorkey=${key}/`
                              : ""
                          }`,
                          priceCurrency: "USD",
                          price: el.price,
                          availability: "https://schema.org/InStock",
                        };
                      }
                    })
                  )
                : {
                    "@type": "Offer",
                    url: `https://frankdarling.com/diamonds/diamond-${data["Diamond ID"]}/`,
                    priceCurrency: "USD",
                    price: data["Cost"],
                    availability: "https://schema.org/InStock",
                  },
              image: !isDiamond
                ? data.variant_details[data.metal[0]]?.image?.src
                : data.image,
            },
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              itemListElement: compact([
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: "https://frankdarling.com/",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: type,
                  item: `https://frankdarling.com${url}`,
                },
                slug && {
                  "@type": "ListItem",
                  position: 3,
                  name: title,
                  item: `https://frankdarling.com${url}/${slug}/`,
                },
              ]),
            },
            {
              "@context": "https://schema.org",
              "@type": "Offer",
              url: `https://frankdarling.com${url}/${slug}/`,
              price:
                type === "diamonds" ? Math.round(data?.Cost) : getMetalPrice(),
              priceCurrency: "USD",
              availability: "https://schema.org/InStock",
            },
          ]),
        }}
      />
    </Head>
  );
};
